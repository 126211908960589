.notification-container-over {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .notification-container {
    width: 400px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .notification-container h2 {
    margin-top: 0;
  }
  
  .notification-container .form-group {
    margin-bottom: 15px;
  }
  
  .notification-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .notification-container input[type="text"] {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .notification-container button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .notification-container button[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .notification-container .success-message {
    color: #4caf50;
    margin-top: 10px;
  }
  
  .notification-container .error-message {
    color: #f44336;
    margin-top: 10px;
  }
  